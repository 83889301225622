@import "react-toastify/dist/ReactToastify.css";
@import "@fontsource/playfair-display";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #251B12;
  color: #FFF1CC;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "AeroliteScriptCP";
  src: url("./assets/fonts/AeroliteScriptCP.otf");
}

* {
  font-family: "Playfair Display", sans-serif;
}

@layer utilities {
  .font-primary {
    font-family: "Playfair Display";
  }

  .font-second {
    font-family: "AeroliteScriptCP";
  }

  .rotate-y {
    transform: rotateY(180deg);
  }
}

.rsme-twitter-embed {
  div {
    max-width: 100% !important;
  }
}

.no-scrollbar {
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0px
  }

  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}